<template>
  <diva-card>
    <div>
      <template v-if="loading && !getUser">
        <content-loader></content-loader>
      </template>
      <template v-else>
        <el-tabs @tab-click="tabhandler" v-model="activeTab" class="mb-4">
          <el-tab-pane :label="getTrans('messages.account')" name="account"></el-tab-pane>
          <el-tab-pane :label="getTrans('messages.billing')" name="billing"></el-tab-pane>
          <el-tab-pane :label="getTrans('messages.camsites')"  name="camsite"></el-tab-pane>
          <el-tab-pane :label="getTrans('messages.notifications')"  name="notifications"></el-tab-pane>
          <el-tab-pane :label="getTrans('messages.password')"  name="password"></el-tab-pane>
          <el-tab-pane :label="getTrans('messages.privacy')"  name="privacy"></el-tab-pane>
          <el-tab-pane :label="getTrans('messages.subscription')"  name="subscription"></el-tab-pane>
          <el-tab-pane :label="getTrans('messages.payouts')"  name="payouts" v-if="can('users-payout')"></el-tab-pane>
          <el-tab-pane :label="getTrans('messages.postbacks')"  name="postbacks" v-if="can('post-backs')"></el-tab-pane>
        </el-tabs>
        <router-view @save="saveUser" :type="'studio'" :user="getUser" :getError="getError"></router-view>
      </template>
    </div>
  </diva-card>
</template>

<script>
import {ElTabs, ElTabPane} from "element-plus";
import {mapGetters} from "vuex"
import Errors from "@/mixins/Errors";

export default {
  name: "Settings",
  components: {
    [ElTabs.name]: ElTabs,
    [ElTabPane.name]: ElTabPane,
  },
  mixins: [Errors],
  data() {
    return {
      activeTab: 'account',
    };
  },
  computed:{
    ...mapGetters("User", {getUser:"getData", errors:"getErrors", loading:"loading", can: "can"}),
  },
  methods: {
    tabhandler() {
      this.$router.push({name:this.activeTab})
    },
    saveUser(settings) {
      this.$store
        .dispatch("User/saveUser", settings)
        .then(() => {
          this.$toast.success(this.getTrans("messages.settings_saved"));
          this.$store.dispatch("User/refresh");
        })
        .catch(() => {
          this.$toast.error(this.getTrans("messages.cant_save"));
        });
    }
  },
  mounted(){
    this.$store.dispatch("Country/get");
    if (this.$route.name == "settings" || this.$route.name == "account") {
      this.tabhandler();
    } else {
      this.activeTab = this.$route.name;
    }
  }
};
</script>
