<script>
export default {
  methods: {
    /**
     * this.errors should be defined
     * @param field
     * @returns {*|null}
     */
    getError(field) {
      if (this.errors?.[field]) {
        return (this.errors[field] instanceof Array) ? this.errors[field][0] : this.errors[field];
      } else {
        return undefined;
      }
    },
    alertErrors(message = null) {
      let errors = [];
      for (let key in this.errors) {
        errors.push(this.errors[key][0]);
      }
      return this.$swal.fire(
        message || this.getTrans('messages.validation_error'),
        errors.join('</br>'),
        'warning'
      );
    },
    /**
     * Search for is-invalid class inside root ref.
     * Input should inside element with ref="root".
     */
    scrollToFirstError(root = 'root') {
      this.$refs[root].getElementsByClassName('is-invalid')[0].scrollIntoView();
    }
  }
};
</script>
